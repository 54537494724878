@tailwind base;
@tailwind components;
@tailwind utilities;

html,body {
  font-family: Inter, sans-serif;
  @apply min-h-screen bg-slate-50 text-slate-900 dark:bg-zinc-900 dark:text-white
}

.ql-editor {
  font-family: Inter, sans-serif;
  @apply !prose !prose-sm;
}